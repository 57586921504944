import { FC } from 'react';
import { Modal, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from '../../util/Button';
import Text from 'antd/lib/typography/Text';
import { Time } from '../../util/Time';

interface FreeCancelledModalProps {
	onClose?: () => void;
	visible?: boolean;
	permanent?: boolean;
	date?: string;
}

export const FreeCancelledModal: FC<FreeCancelledModalProps> = ({
	permanent = false,
	date,
	onClose,
	visible = true,
}) => {
	const { t } = useTranslation('translations');

	return (
		<Modal
			visible={visible}
			footer={
				<Button type="primary" href="https://my.meetergo.com/martinmeng/hinschg">
					{t('FreeCancelledModal.BookButton')}
				</Button>
			}
			closable={!permanent}
			maskClosable={!permanent}
			onCancel={onClose}
			title={
				date && (
					<Text>
						<Time mode="absolute" date={date} />
					</Text>
				)
			}
		>
			<Space direction="vertical" size="large">
				<Typography.Title level={4}>{t('FreeCancelledModal.Title')}</Typography.Title>
				<div>
					<Typography.Paragraph>
						{t('FreeCancelledModal.Description')}
					</Typography.Paragraph>
					<Typography.Paragraph strong>
						{t('FreeCancelledModal.Support')}
					</Typography.Paragraph>
					<Typography.Paragraph>{t('FreeCancelledModal.Export')}</Typography.Paragraph>
					<Typography.Paragraph>{t('FreeCancelledModal.Contact')}</Typography.Paragraph>
				</div>
			</Space>
		</Modal>
	);
};
