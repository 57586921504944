import { Modal, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from '../../util/Button';
import { useReward } from 'react-rewards';
import { FC, useEffect, useRef } from 'react';
import Text from 'antd/lib/typography/Text';
import { Time } from '../../util/Time';

interface BusinessWelcomeModalProps {
	onClose?: () => void;
	visible?: boolean;
	permanent?: boolean;
	date?: string;
}

export const BusinessWelcomeModal: FC<BusinessWelcomeModalProps> = ({
	onClose,
	date,
	visible = true,
	permanent = false,
}) => {
	const { t } = useTranslation('translations');
	const { reward } = useReward('businessRewardId', 'confetti', {
		spread: 100,
		startVelocity: 25,
		elementCount: 80,
		lifetime: 150,
	});
	const confettiHolder = useRef<HTMLSpanElement | null>(null);

	useEffect(() => {
		if (confettiHolder.current != null) {
			reward();
		}
	}, [confettiHolder.current]);

	return (
		<Modal
			visible={visible}
			footer={
				<Button
					type="primary"
					href="https://my.meetergo.com/martinmeng/hinschg"
					target="_blank"
					onClick={onClose}
				>
					{t('BusinessWelcomeModal.BookButton')}
				</Button>
			}
			closable={!permanent}
			maskClosable={!permanent}
			onCancel={onClose}
			title={
				date && (
					<Text>
						<Time mode="absolute" date={date} />
					</Text>
				)
			}
		>
			<span style={{ height: '0px' }} ref={confettiHolder} id="businessRewardId" />
			<Space direction="vertical" size="large">
				<Typography.Title level={4}>{t('BusinessWelcomeModal.Title')}</Typography.Title>
				<div>
					<Typography.Paragraph>
						{t('BusinessWelcomeModal.Description')}
					</Typography.Paragraph>
					<Typography.Text strong>{t('BusinessWelcomeModal.Outsource')}</Typography.Text>
				</div>
			</Space>
		</Modal>
	);
};
