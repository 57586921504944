import { FC, useEffect, useState } from 'react';
import {
	useUnseenNotificationsQuery,
	UnseenNotificationsQuery,
} from '../../../generated/graphql';
import { useSelectedCompanyStore } from '../../../hooks/useSelectedCompanyStore';
import { Notification } from './Notification';

export const NotificationManager: FC = () => {
	const selectedCompanyId = useSelectedCompanyStore((state) => state.companyId);
	const { data: notificationsData, refetch: refetchNotifications } =
		useUnseenNotificationsQuery(undefined, { enabled: !!selectedCompanyId });
	const [currentNotification, setCurrentNotification] = useState<
		UnseenNotificationsQuery['unseenNotifications'][0] | null
	>(null);

	// Filter notifications for selected company and sort
	useEffect(() => {
		if (notificationsData?.unseenNotifications == null) {
			setCurrentNotification(null);
			return;
		}
		const pendingNotifications =
			notificationsData.unseenNotifications
				.filter(
					(notification) =>
						notification.company == null || notification.company.id === selectedCompanyId,
				)
				.sort((a, b) => {
					// Sort permanent notifications last
					if (a.permanent && !b.permanent) return 1;
					if (!a.permanent && b.permanent) return -1;
					// Then sort by creation date, newest last
					return new Date(b.created).getTime() - new Date(a.created).getTime();
				}) || [];
		if (pendingNotifications.length === 0) {
			setCurrentNotification(null);
			return;
		}
		setCurrentNotification(pendingNotifications[0]);
	}, [notificationsData, selectedCompanyId]);

	if (currentNotification == null) {
		return null;
	}

	return (
		<Notification
			id={currentNotification.id}
			notificationKey={currentNotification.key}
			permanent={currentNotification.permanent}
			date={currentNotification.created}
			onClose={() => {
				setCurrentNotification(null);
				refetchNotifications();
			}}
		/>
	);
};
