import { FC, useEffect, useRef, useState } from 'react';
import {
	NotificationKey,
	useMarkNotificationAsSeenMutation,
} from '../../../generated/graphql';
import { BusinessWelcomeModal } from './BusinessWelcomeModal';
import { FreeCancelledModal } from './FreeCancelledModal';

interface NotificationProps {
	id: number;
	notificationKey: NotificationKey;
	permanent: boolean;
	onClose?: () => void;
	date?: string;
}

export const Notification: FC<NotificationProps> = ({
	id,
	notificationKey,
	permanent,
	onClose,
	date,
}) => {
	const { mutateAsync: markAsSeen } = useMarkNotificationAsSeenMutation();
	const notificationRef = useRef<HTMLDivElement>(null);
	const visibilityTimeoutRef = useRef<NodeJS.Timeout>();
	const observerRef = useRef<IntersectionObserver>();

	// Handle marking notifications as seen
	useEffect(() => {
		if (permanent && notificationRef.current) {
			// Create intersection observer to track visibility
			observerRef.current = new IntersectionObserver(
				(entries) => {
					const entry = entries[0];
					if (entry.isIntersecting) {
						// Start timer only when notification becomes visible
						visibilityTimeoutRef.current = setTimeout(() => {
							markAsSeen({ id });
						}, 3000);
					} else {
						// Clear timer if notification becomes hidden
						if (visibilityTimeoutRef.current) {
							clearTimeout(visibilityTimeoutRef.current);
						}
					}
				},
				{ threshold: 0.5 }, // Consider visible when 50% in view
			);

			observerRef.current.observe(notificationRef.current);

			return () => {
				if (visibilityTimeoutRef.current) {
					clearTimeout(visibilityTimeoutRef.current);
				}
				if (observerRef.current) {
					observerRef.current.disconnect();
				}
			};
		}
	}, [id, permanent]);

	const handleClose = async () => {
		if (!permanent) {
			// For non-permanent notifications, mark as seen on close
			await markAsSeen({ id });
		}
		onClose?.();
	};

	const [modalVisible, setModalVisible] = useState(true);

	const handleModalClose = () => {
		setModalVisible(false);
		handleClose();
	};

	return (
		<div ref={notificationRef}>
			{(() => {
				switch (notificationKey) {
					case NotificationKey.BusinessWelcome:
						return (
							<BusinessWelcomeModal
								permanent={permanent}
								visible={modalVisible}
								onClose={handleModalClose}
								date={date}
							/>
						);
					case NotificationKey.FreeCancelled:
						return (
							<FreeCancelledModal
								permanent={permanent}
								visible={modalVisible}
								onClose={handleModalClose}
								date={date}
							/>
						);
					// case NotificationKey.SubscriptionRequired:
					// 	return <SubscriptionRequiredModal visible={modalVisible} onClose={handleModalClose} />;
					default:
						return null;
				}
			})()}
		</div>
	);
};
