import { Col, Row, Space, Spin, Typography } from 'antd';
import {
	AccessLevel,
	CreateChannelMutation,
	CreateCompanyMutation,
	FeatureNames,
	IncidentCategory,
	IncidentType,
	PhoneCallDataFragment,
	useCompanyChannelsQuery,
	useSelfCompaniesQuery,
	useUpdateOnboardingMutation,
} from '../../../generated/graphql';
import { GqlErrorResult } from '../../util/GqlErrorResult';
import './Dashboard.less';
import { useTranslation } from 'react-i18next';
import { JoinCompanyModal, CreateCompanyModal } from '../CompanyModal';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../hooks/useAuth';
import { CreateChannelModal } from '../CreateChannelModal';
import { useSelectedCompanyStore } from '../../../hooks/useSelectedCompanyStore';
import { WelcomeModal } from '../CompanySetupModal';
import { Joyride } from '../../util/Joyride';
import { StaggerAnimationChild, StaggerAnimationParent } from '../../util/Animation';
import { useIncidentFilterStore } from '../IncidentsFilter';
import { CompanyOnboardingChecklist } from './Checklist';
import { Companies } from './Companies';
import { Channels } from './Channels';
import { Incidents, IncidentsHandles } from './Incidents';
import { Button } from '../../util/Button';
import { Helmet } from 'react-helmet';
import { accessLevelAbove } from '../../../lib/util';
import { CallcenterForm } from './CallcenterForm';
import DashboardPhoneCalls from './DashboardPhoneCalls';
import { FeatureToggle } from '../../util/FeatureFlags';
import { useFeatureLock } from '../FeatureLock';
import { NotificationManager } from '../../Actionables/Notification/NotificationManager';

export const Dashboard = () => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', { keyPrefix: 'Dashboard' });
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const { data, isLoading, isSuccess, isError, error, refetch } = useSelfCompaniesQuery(
		{},
		{ enabled: isAuthenticated },
	);
	const [welcomeVisible, setWelcomeVisible] = useState(false);
	const [createCompanyVisible, setCreateCompanyVisible] = useState(false);
	const [joinCompanyVisible, setJoinCompanyVisible] = useState(false);
	const [onboardingVisible, setOnboardingVisible] = useState(false);
	const [createChannelModalVisible, setCreateChannelModalVisible] = useState(false);
	const updateOnboarding = useUpdateOnboardingMutation();
	const [needSetup, setNeedSetup] = useState<boolean | undefined>(undefined);
	const navigate = useNavigate();
	const [selectedCompany, setSelectedCompany, resetSelectedCompany] =
		useSelectedCompanyStore((state) => [state.company, state.setCompany, state.reset]);
	const [filterChannelId, setFilterChannelId] = useIncidentFilterStore((s) => [
		s.channelId,
		s.setChannelId,
	]);
	const selfAccessLevel = useSelectedCompanyStore(
		(state) => state.company?.selfAccessLevel,
	);
	const {
		data: channelsData,
		isLoading: channelsIsLoading,
		isSuccess: channelsIsSuccess,
		isError: channelsIsError,
		refetch: channelsRefetch,
	} = useCompanyChannelsQuery(
		{ id: selectedCompany?.id ?? 0 },
		{ enabled: selectedCompany != null },
	);
	const channels = channelsData?.company.channels;
	const incidentsRef = useRef<IncidentsHandles>(null);
	const { locked: phoneCallFeatureLocked } = useFeatureLock(FeatureNames.PhoneCall);

	useEffect(() => {
		if (!data) return;
		if (data.self.companies) {
			const newNeedSetup = !selectedCompany && data.self.companies.length === 0;
			if (needSetup === undefined) {
				setNeedSetup(newNeedSetup);
			}
		}
		setWelcomeVisible(!createCompanyVisible && !joinCompanyVisible && needSetup === true);
		// if (
		// 	!needSetup &&
		// 	!createCompanyVisible &&
		// 	!joinCompanyVisible &&
		// 	!(data?.self.onboarding?.dashboardCompleted === true)
		// )
		// 	setInterval(() => setOnboardingVisible(true), 1000);
	}, [
		needSetup,
		welcomeVisible,
		createCompanyVisible,
		joinCompanyVisible,
		onboardingVisible,
		selectedCompany,
		data,
	]);

	const onWelcomeSetup = () => {
		setWelcomeVisible(false);
		setCreateCompanyVisible(true);
	};

	const onWelcomeJoin = () => {
		setWelcomeVisible(false);
		setJoinCompanyVisible(true);
	};

	const onJoin = () => {
		setJoinCompanyVisible(true);
	};

	const onCreate = () => {
		setCreateCompanyVisible(true);
	};

	const onNewCompany = (
		newCompany: Omit<
			Exclude<CreateCompanyMutation['createCompany']['company'], null | undefined>,
			'channels'
		>,
	) => {
		data?.self?.companies?.push({ ...newCompany, channels: [] }); // TODO: figure out how to systematically handle channels
		setNeedSetup(false);
	};

	const onCreateChannel = () => setCreateChannelModalVisible(true);

	const onNewChannel = (
		newChannel: Exclude<CreateChannelMutation['createChannel'], null | undefined>,
	) => {
		channels && channels.push(newChannel);
		channelsRefetch();
		setCreateChannelModalVisible(false);
	};

	const joyrideSteps = [
		{
			target: '.dashboard',
			content: (
				<Typography.Paragraph>{t('Dashboard.Onboarding.Intro')}</Typography.Paragraph>
			),
			disableBeacon: true,
		},
		{
			target: '.dashboardIncidents',
			content: (
				<Typography.Paragraph>{t('Dashboard.Onboarding.Incidents')}</Typography.Paragraph>
			),
			disableBeacon: true,
		},
		{
			target: '.dashboardChannels',
			content: (
				<Typography.Paragraph>{t('Dashboard.Onboarding.Channels')}</Typography.Paragraph>
			),
			disableBeacon: true,
		},
		{
			target: '.channelCardSettings',
			content: (
				<Typography.Paragraph>
					{t('Dashboard.Onboarding.ChannelSettings')}
				</Typography.Paragraph>
			),
			disableBeacon: true,
			spotlightClicks: true,
			hideFooter: true,
		},
	];

	const onChannelSelect = (index: number) => {
		if (channels == null || channels.length === 0) return;
		setFilterChannelId(index > -1 ? channels[index].id : undefined);
	};

	const onCreateIncidentFromPhoneCall = (pC: PhoneCallDataFragment) => {
		incidentsRef.current?.openCreateIncidentModal({
			subject: t('CreateIncidentModal.FromPhoneCallDefault.Subject', {
				externalService: pC.externalServiceName,
				externalId: pC.externalId,
			}),
			channelId: pC.channel?.id,
			phoneCallId: pC.id,
			hinter: {
				anonymous: false,
				phone: pC.fromNumber,
			},
			category: IncidentCategory.Uncategorized,
			type: IncidentType.Voice,
		});
	};

	if (isError) return <GqlErrorResult error={error} />;
	if (isLoading || !data) return <Spin />;

	return (
		<>
			<Helmet>
				<title>{t('Meta.Dashboard.Title')}</title>
				<meta name="description" content={t('Meta.Dashboard.Description')} />
			</Helmet>
			<WelcomeModal
				visible={welcomeVisible}
				onSetup={onWelcomeSetup}
				onJoin={onWelcomeJoin}
				onClose={() => setWelcomeVisible(false)}
			/>
			<JoinCompanyModal
				visible={joinCompanyVisible}
				onSuccess={onNewCompany}
				onClose={() => setJoinCompanyVisible(false)}
			/>
			<CreateCompanyModal
				visible={createCompanyVisible}
				onSuccess={onNewCompany}
				onClose={() => setCreateCompanyVisible(false)}
			/>
			{selectedCompany ? (
				<CreateChannelModal
					visible={createChannelModalVisible}
					companyId={selectedCompany?.id}
					onClose={() => setCreateChannelModalVisible(false)}
					onSuccess={onNewChannel}
				/>
			) : null}
			<Joyride
				steps={joyrideSteps}
				continuous
				showProgress
				run={onboardingVisible}
				showSkipButton={true}
				callback={(data) => {
					const { index, size } = data;
					if (index === size - 1)
						updateOnboarding.mutateAsync({ input: { dashboardCompleted: true } });
				}}
			/>
			{!phoneCallFeatureLocked &&
				accessLevelAbove(AccessLevel.Callcenter, selfAccessLevel) && (
					<DashboardPhoneCalls onNewIncident={onCreateIncidentFromPhoneCall} />
				)}
			{needSetup !== undefined && !needSetup && (
				<StaggerAnimationParent>
					<Row style={{ marginTop: '32px' }} gutter={[32, 32]}>
						{accessLevelAbove(AccessLevel.User, selfAccessLevel) &&
							selectedCompany?.onboarding?.visible && (
								<Col span={24}>
									<CompanyOnboardingChecklist />
								</Col>
							)}
						{(data?.self.companies.length ?? 0) > 1 ||
						((selectedCompany?.tier ?? 0) > 1 &&
							accessLevelAbove(AccessLevel.User, selfAccessLevel)) ? (
							<Col id="dashboardCompanies" className="dashboardCompanies" span={24}>
								<StaggerAnimationChild>
									<Space
										direction="vertical"
										style={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}
									>
										<Row gutter={[8, 8]}>
											<Col flex="auto">
												<Typography.Title level={3} style={{ textAlign: 'left' }}>
													{t('Companies')}
												</Typography.Title>
											</Col>
											<Col>
												{/* <Button onClick={onJoin} type="ghost">
													{t('Join')}
												</Button> */}
											</Col>
										</Row>
										<Row>
											<Col>
												<Companies
													onCreate={onCreate}
													companies={
														data?.self.companies == null ? [] : data.self.companies
													}
												/>
											</Col>
										</Row>
									</Space>
								</StaggerAnimationChild>
							</Col>
						) : null}
						<Col
							id="dashboardChannels"
							className="dashboardChannels"
							xs={24}
							sm={24}
							md={8}
							lg={8}
							xl={8}
						>
							<StaggerAnimationChild>
								<Space
									direction="vertical"
									style={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}
								>
									<Row>
										<Col>
											<Typography.Title level={3} style={{ textAlign: 'left' }}>
												{t('Channels')}
											</Typography.Title>
										</Col>
									</Row>
									<Row>
										<Col>
											<Channels
												selectedId={filterChannelId}
												channels={channels}
												onCreate={
													accessLevelAbove(AccessLevel.User, selfAccessLevel)
														? onCreateChannel
														: undefined
												}
												onSelect={onChannelSelect}
											/>
										</Col>
									</Row>
								</Space>
							</StaggerAnimationChild>
						</Col>
						<Col
							id="dashboardIncidents"
							className="dashboardIncidents"
							xs={24}
							sm={24}
							md={16}
							lg={16}
							xl={16}
						>
							<Space direction="vertical" size="large" style={{ width: '100%' }}>
								{selfAccessLevel === AccessLevel.Callcenter && (
									<CallcenterForm channelId={filterChannelId} />
								)}
								<Incidents ref={incidentsRef} />
							</Space>
						</Col>
					</Row>
				</StaggerAnimationParent>
			)}
			<NotificationManager />
		</>
	);
};
